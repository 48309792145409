/** @format */

.selected {
  background-color: lightblue;
}

.row {
  display: flex;
}

.row > div,
.row > input,
.row > button {
  flex: 1;
}

.button {
  border: 1px solid gray;
  border-radius: 5px;
  padding: 3px 10px;
  margin: 2px;
}

.f3 {
  flex: 3 !important;
}
