/** @format */

.layout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.layout > div:first-child {
  flex: 1;
  width: 100%;
}

.layout > div:last-child {
  padding: 25px;
  flex: 5;
}
