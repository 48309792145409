.pageHeader {
    background-color: rgb(167, 160, 156);
    color: white;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.pageHeader .home {
    font-size: 1.5rem;
    font-weight: bold;
}

.pageHeader div {
    color: white;
    font-size: 1.2rem;
    padding: 5px 15px;
}

@media print {
    .pageHeader {
        display: none;
    }
}